<template>
  <b-modal id="reason-modal" ref="reason-modal" :title="title" size="xl" :hide-header-close="true"
    :no-close-on-backdrop="true" @cancel="resetModal" @hide="resetModal">
    <b-overlay :show="loading">
      <b-container fluid class="p-0">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col>
              <basic-text-editors label="Nội dung" placeholder="Vui lòng nhập lý do ..." name="answer"
                :value.sync="form.answer" data-vv-as="Vui lòng nhập lý do ...">
              </basic-text-editors>
            </b-col>
          </b-row>
        </form>
      </b-container>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button class="btn btn-plain ml-2" href="#" @click.stop="handleCancel" tabindex="0">
          Huỷ
        </b-button>
        <b-button class="btn btn-success ml-2" href="#" @click.stop="handleSubmit" tabindex="0">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Cập nhật
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ReasonModal',
  components: {},
  props: {
    targetItem: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  computed: {
    type() {
      return this.popupType;
    },
  },
  watch: {
    id: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.loadData(newVal);
        }
      },
    },
    form: {
      deep: true,
      handler(newVal) {
        if (
          Object.keys(newVal).length &&
          this.initialFrom !== JSON.stringify(newVal)
        ) {
          this.isChange = true;
        }
      },
    },
  },
  data() {
    return {
      detail: {},
      validationState: {},
      error: {},
      form: {},
      loading: false,
      isChange: null,
      initialFrom: null,
    };
  },

  methods: {
    async handleSubmit() {
      try {
         console.log('form.answer',this.form.answer);
        const choice = await this.handleConfirm();
        if (choice) await this.changeStatus(this.targetItem);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.$bvModal.hide('reason-modal');
      }
    },

    async changeStatus(item) {
      this.$store.commit('context/setLoading', true);
      const formData = new FormData();
      const keyBoolean = [
        'followOaFlag',
        'joinGroupchatFlag',
        'downloadAppFlag',
        'loginFlag',
        'boardingBookingFlag',
        'boardingTestingFlag',
        'boardingDoneFlag',
        'joinHeathCourseFlag',
        'isDeleted',
      ];
      Object.keys(item).forEach((key) => {
        if (key == 'isDeleted') {
          formData.append('isDeleted', !!item.isDeleted);
        } else if (key === 'timeAccepted' || key === 'gender') {
          //
        } else if (key === 'status') {
          const newStatus = this.title === 'Lý do chuyển người dùng thành khách hàng tiềm năng' ? 9 : 0;
          formData.set('status', newStatus);
          formData.append('ReasonOut',this.form.answer)
        } else{
          formData.append(
            key,
            keyBoolean.includes(key) ? !!item[key] : item[key] || '',
          );
        }
      });
      try {
        // Send the FormData using the PUT method
        await this.$api.put(`/CustomerReceives/${item.id}`, formData);
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
      } catch (error) {
        if (error) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
      } finally {
        this.$store.commit('context/setLoading', false);
        this.$emit('load-data');
      }
    },
    resetModal() {
      this.form = {};
      this.initialFrom = {};
      this.isChange = false;
    },
    async handleConfirm() {
      return await this.$swal({
        title: 'Xác nhận?',
        text: `Bạn đã lưu thay đổi dữ liệu chưa, xác nhận thoát?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      });
    },
    async handleCancel() {
      let confirm = await this.handleConfirm();
      confirm == true ? this.$bvModal.hide('reason-modal') : null;
    },
  },
  mounted() {
    if (this.form && this.targetItem) {
      this.$set(this.form, 'answer', this.targetItem.reasonOut);
    }
  },
};
</script>

<style lang="scss"></style>